/* start video section style  */
.eventCompetitionContainer {
  padding: var(--pagePadding);
}
.eventCompetitionVideo {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  height: 400px;
  width: 60%;
  background-size: cover;
  border: 2px solid var(--mainRed);
  border-radius: 50px;
  box-shadow: 0px -110px 100px 0px #0c0c0c inset;
  padding: 25px;
}
.eventCompetitionVideo h1 {
  font-weight: 500;
  font-size: 40px;
  color: white;
}
.eventCompetitionVideo div {
  position: absolute;
  bottom: 30px;
  width: 80%;
}
.eventCompetitionVideo p {
  border: 1px solid var(--mainRed);
  width: fit-content;
  padding: 2px 10px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 12px;
}
.eventCompetitionVideo p:hover {
  background-color: var(--mainRed);
}
.eventCompetitionVideo .playIcon {
  fill: rgba(0, 0, 0, 0.5);
  font-size: 65px;
  padding: 18px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  position: absolute;
  bottom: 30px;
  right: 25px;
  cursor: pointer;
  z-index: 3;
}
.eventCompetitionVideo .playIcon:hover {
  background-color: white;
}
/* end video section style */

/* start background  */
.eventCompetitionContainer .backgroundEventCompetition {
  position: absolute;
  width: 70%;
  height: 350px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.eventCompetitionContainer .shape {
  height: 100px;
  width: 100px;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 -6px 4px rgba(0, 0, 0, 0.12) inset,
    5px 1px 4px rgba(0, 0, 0, 0.1) inset, 0px 4px 40px #0b2a38;
  animation-name: floating;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.eventCompetitionContainer .shape:first-child {
  background-image: linear-gradient(var(--mainRed), #b4300a);
  left: 20px;
  top: -20px;
  height: 26px;
  width: 26px;
  animation-duration: 3s;
}

.eventCompetitionContainer .shape:nth-child(2) {
  background-image: linear-gradient(var(--mainGreen), #4b6b05);
  right: 30px;
  top: -30px;
  height: 53px;
  width: 53px;
  animation-duration: 4s;
  animation-direction: reverse;
}

.eventCompetitionContainer .shape:nth-child(3) {
  background-image: linear-gradient(var(--mainBlue), #0b6c9d);
  left: 50px;
  bottom: -50px;
  height: 93px;
  width: 93px;
  animation-duration: 5s;
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
/* end background */


/* start line style */
.line {
  margin-top: 5%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.line .logoContainer {
  width: 70px;
  height: 70px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 2px solid var(--mainRed);
  border-radius: 50%;
}

.subLine {
  background-color: var(--mainRed);
  width: 40%;
  height: 2px;
  position: relative;
}

.subLine::after {
    content: "";
    width: 10px;
    height: 10px;
    border: 2px solid var(--mainRed);
    position: absolute;
    right: -10px;
    border-radius: 50%;
    top: -4px;
}
.subLine:first-of-type:after {
    left: -10px;
}

.line .logoContainer img {
  width: 100%;
}
/* end line style */

/* start competitions section */
.competitionsContainer {
  display: flex;
  gap: 70px;
}
.eventCompetitions .competition {
  cursor: pointer;
  border: 2px solid var(--secondFontColor);
  border-radius: 50px;
}
.eventCompetitions .competition:hover {
  border: 2px solid var(--mainRed);
}
.eventCompetitions .competition:active {
    transform: scale(0.98);
}

.eventCompetitions .competitionImage {
  height: 300px;
  background-size: cover;
  border-top-right-radius: 50px;
  border-top-left-radius: 50px;
}
.eventCompetitions .competition .info {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
  padding: 25px;
}

.eventCompetitions .competition .info p {
  color: var(--secondFontColor);
}

.eventCompetitions .competition .info button {
  background-color: transparent;
  border: 1px solid var(--mainRed);
  padding: 5px 10px;
  border-radius: 30px;
  cursor: pointer;
}

.eventCompetitions .competition .info button:hover {
  background-color: var(--mainRed);
}

.eventCompetitions .competition .info button:active {
  transform: scale(0.96);
}
/* end competitions section */

@media (max-width: 1000px) {
    .eventCompetitionVideo {
        width: 90%;
    }
    .eventCompetitionContainer .backgroundEventCompetition {
        width: 100%;
    }
}

@media (max-width: 700px) {
    .competitionsContainer {
        flex-direction: column;
        gap: 30px;
    }
    .eventCompetitions .competition {
        margin-top: 30px;
    }

    .eventCompetitionVideo h1 {
        font-weight: 500;
        font-size: 30px;
    }
    .eventCompetitionVideo .playIcon {
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
}
