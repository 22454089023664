:root {
    --mainRed: #f25123;
    --mainGreen: #7fba00;
    --mainBlue: #02a3f1;
    --errorColor: rgb(247, 92, 97);
    --glassColor: rgba(255, 255, 255, 0.07);
    --borderRadius: 20px;
}

/* start background  */
.background {
    position: absolute;
    width: 370px;
    height: 480px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.shape {
    height: 100px;
    width: 100px;
    position: absolute;
    border-radius: 50%;
    box-shadow: 0 -6px 4px rgba(0, 0, 0, 0.12) inset, 5px 1px 4px rgba(0, 0, 0, 0.1) inset, 0px 4px 40px #0b2a38;
    animation-name: floating;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.shape:first-child {
    background-image: linear-gradient(var(--mainRed), #b4300a);
    right: -10px;
    top: -10px;
    height: 26px;
    width: 26px;
    animation-duration: 3s;
}

.shape:nth-child(2) {
    background-image: linear-gradient(var(--mainGreen), #4b6b05);
    right: -30px;
    bottom: -30px;
    height: 53px;
    width: 53px;
    animation-duration: 4s;
}

.shape:nth-child(3) {
    background-image: linear-gradient(var(--mainBlue), #0b6c9d);
    left: -60px;
    top: -60px;
    height: 93px;
    width: 93px;
    animation-duration: 5s;
}

@keyframes floating {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}
/* end background */

/* Start Form Editing */
form.registerEventForm {
    max-height: 90%;
    width: 60%;
    overflow: auto;
    position: absolute;
    padding: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: var(--borderRadius);
    background-color: var(--glassColor);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 40px rgba(8, 7, 16, 0.6);
    user-select: none;
}

form.registerEventForm .quest {
    padding: 0 20px;
}

form.registerEventForm .question {
    margin: 20px 0;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

form.registerEventForm .question .questHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
}


form.registerEventForm .question .questHeader span {
    display: inline-block;
    background-color: var(--glassColor);
    font-weight: 500;
    letter-spacing: 2px;
    margin: 5px 0;
    padding: 5px 10px;
    border-radius: 10px;
    font-size: 0.8rem;
    text-align: center;
}

form.registerEventForm .question:first-child{
    border-top:none;
}

form.registerEventForm .question p {
    margin: 0px 5px;
}

form.registerEventForm .question .type .choices {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 10px;
    padding: 10px;
}

form.registerEventForm .question .type .choices .choice {
    position: relative;
    overflow: hidden;
}

form.registerEventForm .question .type .choices .choice .view {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding: 10px 20px;
    background-color: #222;
    border: 2px solid var(--glassColor);
    border-radius: 10px;
    user-select: none;
    transition: 0.2s all ease-in-out;
}

form.registerEventForm .question .type .choices .choice input {
    display: none;
    opacity: 0;
    z-index: 2;
    cursor: pointer;
}

form.registerEventForm .question .type .choices .choice input:checked+.view {
    color: var(--mainBlue);
    border: 2px solid var(--mainBlue);
    background-color: #1C2225;
}

.err{
    height: 0px;
    overflow: hidden;
    color: var(--mainRed);
    transition: 0.2s all ease-in-out;
}

.showErr{
    height: 19px;
}
/* end Form Editing */


/* start login header */
.headerContainer {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #1d1d1d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    padding: 20px;
}

.headerContainer div:first-child{
    display: flex;
    align-items: center;
}

.logoImage {
    width: 80px;
}

.communityName p:first-child {
    font-weight: bold;
}

.communityName p {
    font-size: 22px;
    margin-left: 20px;
    white-space: nowrap;
}

.home {
    cursor: pointer;
    padding: 10px 10px;
    border-radius: 10px;
    background-image: linear-gradient(#db4d25, #cb5331);
    box-shadow: 0 -4px 4px #bd401d inset, 0 4px 4px rgba(255, 255, 255, 0.1) inset;
}

.home .icon {
    fill: #fff;
}
/* end login header */


/* start general input style  */
.registerEventForm input,
.registerEventForm textarea {
    background-color: transparent;
    border: none;
    outline: none;
    margin: 0 10px;
    caret-color: white;
    color: white;
    font-size: 1.25rem;
}

.registerEventForm textarea {
    display: block;
    background-color: #242424;
    width: 100%;
    height: 50px;
    min-height: 50px;
    max-height: 200px;
    margin: 0;
    margin-top: 10px;
    padding: 10px 20px;
    border-radius: 10px;
    resize: vertical;
    border: 2px solid transparent;
    transition: 0.1s border ease;
}

.registerEventForm textarea:focus {
    border: 2px solid var(--mainBlue);
}

.registerSuccessfully button{
    border: none;
    cursor: pointer;
    font-weight: bold;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border-radius: 30px;
    background-image: linear-gradient(#db4d25, #cb5331);
    box-shadow: 0 -4px 4px #bd401d inset, 0 4px 4px rgba(255, 255, 255, 0.1) inset;
}
/* end general input style  */

/* Start Submit BTN */
.subConainer{
    padding: 20px;
}

.loginBtn {
    width: 100%;
    font-size: 20px;
    padding: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 30px;
    background-image: linear-gradient(#db4d25, #cb5331);
    box-shadow: 0 -4px 4px #bd401d inset, 0 4px 4px rgba(255, 255, 255, 0.1) inset;
}

.loginBtn:active {
    transform: scale(0.98);
}
/* end submit BTN */

/* Responsive */
@media (max-width: 769px) {
    form.registerEventForm {
        max-height: 80%;
        width: 80%;
    }

    form.registerEventForm .question .questHeader {
        flex-direction: column;
    }
    
    form.registerEventForm .question .questHeader div {
        text-align: right;
        width: 100%;
    }

    form.registerEventForm .question .type .choices {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .registerEventForm textarea {
        height: 70px;
    }  
    
    .registerEventForm input,
    .registerEventForm textarea {
        font-size: 1.05rem;
    }
}

@media (max-width: 500px) {
    .background {
        width: 200px;
        height: 500px;
    }

    .registerEventForm input {
        font-size: 16px;
    }

    select {
        font-size: 16px;
    }

    form.registerEventForm .question .type .choices {
        grid-template-columns: 1fr;
    } 

    .communityName {
        display: none;
    }

    .logoImage {
        width: 50px;
    }
}
/* end Responsive  */