.loadingSec{
  position: fixed;
  inset: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: black;
  user-select: none;
  cursor: wait;
}

.loadingSec p{
  margin-top: 20px;
  text-transform: capitalize;
  padding: 0 2rem;
  font-size: 1.5rem;
  text-align: center;
}

.loader {
  width: 70px;
  height: 70px;
  border: 5px solid #FFF;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  animation: pulse 0.8s linear infinite;
}
.loader:after {
  content: '';
  position: absolute;
  width: 70px;
  height: 70px;
  border: 5px solid #FFF;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  animation: scaleUp 0.8s linear infinite;
}

@keyframes scaleUp {
  0% { transform: translate(-50%, -50%) scale(0) }
  60% , 100% { transform: translate(-50%, -50%)  scale(1)}
}
@keyframes pulse {
  0% , 60% , 100%{ transform:  scale(1) }
  80% { transform:  scale(1.2)}
}