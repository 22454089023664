.loadingSec {
    position: fixed;
    bottom: 1vh;
    right: 1vw;
    z-index: 9998;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(15, 158, 77);
    user-select: none;
    cursor: wait;
    padding: 20px;
    border-radius: 25px;
    pointer-events: none;
    transition: 0.3s all ease-in;
}

.loadingSec p {
    margin-top: 20px;
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
}

.loader {
    width: 50px;
    height: 50px;
    border: 5px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    position: relative;
    animation: pulse 0.8s linear infinite;
}

.loader:after {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    border: 5px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    animation: scaleUp 0.8s linear infinite;
}

@keyframes scaleUp {
    0% {
        transform: translate(-50%, -50%) scale(0)
    }

    60%,
    100% {
        transform: translate(-50%, -50%) scale(1)
    }
}

@keyframes pulse {

    0%,
    60%,
    100% {
        transform: scale(1)
    }

    80% {
        transform: scale(1.2)
    }
}