.dashboard {
    display: grid;
    grid-template-columns: 20% 80%;
    column-gap: 25px;
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.dashboard .pageContainer {
    height: calc(100vh - 25px);
    background-color: #191919;
    border-bottom-left-radius: 10rem;
    padding: 20px 5rem 20px 4rem;
    overflow-y: auto;
}

.dashboard .pageContainer h4.headline {
    color: #EF6848;
    font-size: 3rem;
    text-transform: capitalize;
    margin: 10px 0;
    font-weight: 600;
}

.dashboard .pageContainer form {
    position: static;
    transform: none;
    background-color: transparent;
    box-shadow: none;
    border: none;
    width: 100%;
    padding: 10px 0;
    backdrop-filter: none;
}

.dashboard .pageContainer form .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
}

.dashboard .pageContainer form .row .breifTXT {
    display: none;
    text-align: left;
}

.dashboard .pageContainer form .row .check {
    margin-bottom: 20px;
}


.dashboard .pageContainer form .dataEntry {
    background-color: #1F1F1F;
    display: flex;
    flex-direction: row-reverse;
    border-radius: 50px;
    padding: 10px 20px;
    margin-bottom: 20px;
    width: 100%;
}

.dashboard .pageContainer form .dataEntry input,
.dashboard .pageContainer form .dataEntry textarea {
    border: none;
    background-color: transparent;
    outline: none;
    width: 100%;
    max-width: none;
    font-size: 2rem;
    color-scheme: dark;
    transition: 0.3s all ease-in-out;
}

.dashboard .pageContainer form .dataEntry textarea {
    min-height: 300px;
    max-height: 400px;
    resize: vertical;
}

.dashboard .pageContainer form .photoUplad {
    position: relative;
    padding: 0;
    background-color: transparent;
}

.dashboard .pageContainer form .photoUplad input {
    position: absolute;
    inset: 0;
    opacity: 0;
    cursor: pointer;
}

.dashboard .pageContainer form .photoUplad .previewer {
    width: 100%;
    padding: 3rem 2rem;
    border: 5px dashed #252525;
    border-radius: 40px;
}

.dashboard .pageContainer form .photoUplad .previewData {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 20px;
    text-align: left;
}

.dashboard .pageContainer form .photoUplad .previewData img {
    width: 100%;
    height: 250px;
    border-radius: 20px;
    object-fit: contain;
}

.dashboard .pageContainer form .photoUplad .previewData table {
    white-space: pre-wrap;
    width: fit-content;
}

.dashboard .pageContainer form .photoUplad .previewData table tr td {
    padding: 0 20px;
    font-size: 1.2rem;
    overflow: hidden;
}

.dashboard .pageContainer form .photoUplad .previewData table tr td.danger {
    color: #F20A41;
    font-weight: 700;
    font-size: 1.5rem;
}

.dashboard .pageContainer form .photoUplad .previewData table tr td.warning {
    font-weight: 600;
}

.dashboard .pageContainer form .photoUplad label {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 200px;
    border-radius: 40px;
}

.dashboard .pageContainer form .photoUplad label p {
    font-size: 2rem;
    color: #9b9b9b;
}

.dashboard .pageContainer form .photoUplad label p:nth-child(3) {
    font-size: 1.2rem;
    color: #EF6848;
}

.dashboard .pageContainer form .dataEntry input:disabled {
    color: #555;
}

.dashboard .pageContainer form .dataEntry input:disabled+label .icon {
    fill: rgb(240, 12, 58) !important;
}

.dashboard .pageContainer form .dataEntry input:focus+label .icon,
textarea:focus+label .icon {
    fill: #fff !important;
}

.dashboard .pageContainer form .dataEntry .icon {
    fill: #aaa;
    margin-right: 20px;
    transition: 0.2s fill ease-in;
}

.dashboard .pageContainer form .userSellections {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    margin-top: 2rem;
    column-gap: 2rem;
    user-select: none;
}

.dashboard .pageContainer form .check {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    font-size: 2rem;
}

.dashboard .pageContainer form .check div {
    background-color: #1F1F1F;
    position: relative;
    border-radius: 50px;
    overflow: hidden;
    width: 150px;
    height: 80px;
}

.dashboard .pageContainer form .check div input {
    position: absolute;
    inset: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 2;
}

.dashboard .pageContainer form .check div input:checked+label {
    position: absolute;
    left: calc(100% - 75px);
    right: 10px;
    background-color: #EF6848;
}

.dashboard .pageContainer form .check div label {
    display: block;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 5px;
    right: auto;
    transform: translateY(-50%);
    background-color: #2F2F2F;
    transition: 0.3s all ease-in-out;
}

.pageContainer .save {
    text-align: right;
}

.pageContainer .save button {
    border: none;
    cursor: pointer;
    border-radius: 30px;
    padding: 1rem 2rem;
    font-size: 2.5rem;
    color: #000;
    font-weight: 300;
    background-image: linear-gradient(#db4d25, #cb5331);
    box-shadow: 0 -4px 4px #bd401d inset, 0 4px 4px rgba(255, 255, 255, 0.1) inset;
}

@media (max-width: 769px) {
    main.mainContainer {
        min-height: 100vh;
        background-color: #fff;
    }

    .dashboard {
        height: auto;
        grid-template-columns: 1fr;
    }

    .dashboard .pageContainer {
        height: 88vh;
        padding: 20px 2rem 20px 3rem;
        width: calc(100vw - 2vh);
        margin-left: 2vh;
    }

    .dashboard .pageContainer form .dataEntry input,
    .dashboard .pageContainer form .dataEntry textarea {
        font-size: 1.8rem;
    }

    .dashboard .pageContainer form .row {
        flex-direction: column;
    }

    .dashboard .pageContainer form .row .breifTXT {
        display: block;
    }

    .dashboard .pageContainer form .row .check {
        width: 100%;
        justify-content: space-between;
    }

    .dashboard .pageContainer form .userSellections {
        grid-template-columns: 1fr;
        row-gap: 2rem;
    }

    .dashboard .pageContainer form .photoUplad .previewData table tr td {
        max-width: 150px;
    }
}

@media (max-width: 426px) {
    html {
        font-size: 12px;
    }

    .dashboard .pageContainer form .dataEntry>input {
        margin-left: 0;
    }

    .dashboard .pageContainer form .row .breifTXT {
        font-size: 1.8rem;
    }

    .dashboard .pageContainer form .check div {
        width: 120px;
        height: 60px;
    }

    .dashboard .pageContainer form .check div label {
        height: 50px;
        width: 50px;
        left: 5px;
        right: 100%;
    }

    .dashboard .pageContainer form .check div input:checked+label {
        position: absolute;
        left: calc(100% - 55px);
        right: 5px;
    }

    .dashboard .pageContainer form .photoUplad .previewData {
        grid-template-columns: 1fr;
        row-gap: 20px;
    }
}