@font-face {
  font-family: "MOAM91";
  src: url("./fonts/MOAM91.ttf") format("truetype");
}

:root {
  --mainRed: #f25123;
  --mainGreen: #7fba00;
  --mainBlue: #02a3f1;
  --errorColor: rgba(247, 92, 97, 0.5);
  --glassColor: rgba(255, 255, 255, 0.07);
  --borderRadius: 20px;
  --secondFontColor: rgba(255, 255, 255, 0.5);
  --pagePadding: 0 4% 2.5rem;
}

body {
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0C0C0C;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
  text-transform: capitalize;
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background-color: #0C0C0C;
}

::-webkit-scrollbar-thumb {
  background-color: #565656;
  border-radius: 20px;
}