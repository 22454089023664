nav {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    padding: 0 4%;
    background-color: #0c0c0c;
    width: 100%;
    z-index: 2;
}

.navegation {
    display: flex;
    gap: 40px;
}

li {
    list-style: none;
    cursor: pointer;
    white-space: nowrap;
}

.logo img {
    width: 53.75px;
    height: 45px;
    cursor: pointer;
}

nav button.login {
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 30px;
    background-image: linear-gradient(#db4d25, #cb5331);
    box-shadow: 0 -4px 4px #bd401d inset, 0 4px 4px rgba(255, 255, 255, 0.1) inset;
    color: black;
}

nav button {
    background-color: transparent;
    display: inline-block;
    padding: 5px 10px;
    cursor: pointer;
    color: white;
}

nav div>ul {
    position: relative;
    list-style: none;
    padding: 0;
}

nav div>ul li button {
    display: block;
    width: 100%;
    padding: 5px 20px;
    margin: 5px 0;
    cursor: pointer;
    font-size: 1rem;
    border: 0;
}

nav div>ul ul li:last-child button {
    background-color: rgb(255, 34, 0);
    margin-bottom: 0;
}

nav div>ul:hover ul {
    opacity: 1;
    pointer-events: all;
    transform: translateX(0%);
}

nav div>ul ul {
    background-color: #151515;
    position: absolute;
    right: 0;
    z-index: 2;
    transform: translateX(10%);
    border-radius: 10px;
    box-shadow: 2px 2px 10px #000;
    top: 100%;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    transition: 0.3s all ease-in-out;
}

button.viewNav {
    background-color: transparent;
    border: none;
    outline: none;
    display: none;
}

nav .sideNav.hide {
    opacity: 0;
    pointer-events: none;
}

nav .sideNav {
    position: fixed;
    inset: 0;
    background-color: #1f1f1f75;
    backdrop-filter: blur(10px);
    opacity: 1;
    pointer-events: all;
    transition: 0.3s all ease-in;
}

nav .sideNav div.overlay {
    position: fixed;
    inset: 0;
    z-index: 1;
}

nav .sideNav .nav {
    display: flex;
    position: fixed;
    left: 0%;
    z-index: 2;
    height: 100vh;
    width: 70%;
    background-color: #0f0f0f;
    flex-direction: column;
    transition: 0.3s all ease-in;
}

nav .sideNav.hide .nav {
    left: -100%;
}

nav .sideNav .header {
    text-align: center;
    padding: 25px 0 10px;
}

nav .sideNav .header img {
    width: 70px;
    margin-bottom: 10px;
}

nav .sideNav .header p {
    line-height: 20px;
    text-transform: uppercase;
}

nav .sideNav hr {
    width: 70%;
    margin: 0 auto;
    opacity: 0.2;
    margin-top: 10px;
}

nav .sideNav .nav .links {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

nav .sideNav ul li button {
    position: relative;
    text-align: left;
    padding: 20px 25px;
    font-size: 1.5rem;
    font-weight: 500;
    display: flex;
    align-items: center;
}

nav .sideNav ul li button.login {
    border-radius: 0;
}

nav .sideNav ul li button.login .icon {
    fill: #000;
}

nav .sideNav ul li button.logout {
    background-color: rgb(234, 24, 59);
    border-radius: 0;
}


nav .sideNav ul li button.logout .icon {
    fill: #fff;
}

nav .sideNav ul li button .icon {
    margin-right: 25px;
}

@media (max-width: 479px) {
    button.viewNav {
        display: inline-block;
    }

    .navegation {
        display: none;
    }

    nav .loginControls {
        display: none;
    }
}

@media (max-width: 359px) {
    .navegation {
        transform: none;
        justify-content: flex-end;
        right: 15px;
        gap: 20px;
    }
}