.logoutWarning {
    position: relative;
    z-index: 100;
    pointer-events: none;
    opacity: 0;
    transition: 0.3s all ease-in-out;
}

.logoutWarning.active {
    pointer-events: all;
    opacity: 1;
}

.logoutWarning .overflow {
    position: fixed;
    inset: 0;
    z-index: 100;
    cursor: pointer;
    background-color: #ffffff4c;
    backdrop-filter: blur(5px);
}

.logoutWarning .logoutForm {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
    background-color: #191919;
    border-radius: 20px;
    box-shadow: 5px 5px 10px #0000001f;
    min-height: 200px;
    text-align: center;
    padding: 2rem 3rem;
}

.logoutWarning .logoutForm p {
    font-size: 2rem;
    font-weight: 600;
}

.logoutWarning .logoutForm button {
    cursor: pointer;
    font-size: 1rem;
    border-radius: 15px;
    background-image: linear-gradient(#db4d25, #cb5331);
    padding: 10px 20px;
}

.logoutWarning .logoutForm button:nth-child(2) {
    background-color: transparent;
    border: 1px solid #cb5331;
    background-image: none;
}

.logoutWarning .logoutForm img {
    width: 250px;
}

section.Dnav {
    position: static;
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100%;
}

section.Dnav * {
    color: #000;
}

section.Dnav .logo {
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 20px 50px;
}

section.Dnav .logo img {
    width: 70px;
}

section.Dnav .logo .details {
    width: 100%;
    margin-left: 20px;
    font-size: 1.2rem;
}

section.Dnav .linksContainer {
    height: 100%;
}

section.Dnav .links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    background-color: #191919;
    border-top-right-radius: 10rem;
    padding: 4rem 0 2rem;
    overflow: hidden;
}

section.Dnav .links ul li button {
    background-color: transparent;
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 2rem;
    text-align: left;
    padding: 20px 3rem;
    color: #fff;
    cursor: pointer;
    transition: 0.2s all ease-in;
}

section.Dnav .links ul li button.logout {
    color: #F20A41;
}

section.Dnav .links ul li button:hover {
    color: #EF6848;
    background-color: #121212;
}

section.Dnav .links ul li button.logout .icon {
    fill: #F20A41;
}

section.Dnav .links ul li button:hover .icon {
    fill: #EF6848;
}

section.Dnav .links ul li button .icon {
    margin-right: 20px;
    fill: #fff;
    transition: 0.2s all ease-in;
}

section.Dnav .sideNavBTN {
    display: none;
}

section.Dnav .sideNavBTN button {
    background-color: transparent;
    outline: none;
    border: none;
    padding: 15px;
}

@media (max-width: 769px) {
    section.Dnav {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 10vh;
        padding: 0 25px;
    }

    section.Dnav .sideNavBTN {
        display: inline-block;
    }

    section.Dnav .logo {
        padding: 0;
        width: fit-content;
    }

    section.Dnav .linksContainer.hidden .overlay {
        opacity: 0;
        pointer-events: none;
    }

    section.Dnav .linksContainer .overlay {
        position: absolute;
        inset: 0;
        z-index: 9;
        background-color: white;
        backdrop-filter: blur(10px);
        opacity: 0.9;
        pointer-events: all;
        transition: 0.2s all ease-in;
    }

    section.Dnav .linksContainer.hidden .links {
        left: -100%;
    }

    section.Dnav .linksContainer .links {
        position: absolute;
        height: 88vh;
        width: 45vw;
        bottom: 0;
        left: 0;
        z-index: 10;
        overflow-y: auto;
        border-top-right-radius: 8rem;
        transition: 0.3s all ease-out;
    }
}

@media (max-width: 425px) {
    section.Dnav .linksContainer .links {
        width: 70vw;
    }
}

@media (max-width: 426px) {
    section.Dnav .logo .details {
        display: none;
    }

    section.Dnav .linksContainer .links {
        width: 80vw;
    }
}

@media (max-width: 376px) {

    section.Dnav .links ul li button {
        font-size: 1.5rem;
    }

}