.loadingSec {
    position: fixed;
    bottom: 1vw;
    right: 1vw;
    max-width: 95vw;
    z-index: 9998;
    background-color: rgb(246, 174, 174);
    user-select: none;
    cursor: pointer;
    padding: 20px 4vw;
    border-radius: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.loadingSec p {
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
    color: brown;
}