.workOperation,
.wrongOP {
    position: relative;
    z-index: 2;
    opacity: 0;
    transition: 0.3s all ease-in-out;
}

.workOperation.ShowOP,
.wrongOP.ShowOP {
    opacity: 1;
}

.dashboard {
    display: grid;
    grid-template-columns: 20% 80%;
    column-gap: 25px;
    width: 100vw;
    height: 100vh;
    background-color: white;
}

.dashboard .pageContainer {
    position: relative;
    height: calc(100vh - 25px);
    background-color: #191919;
    border-bottom-left-radius: 10rem;
    padding: 0 5rem 3rem 4rem;
    overflow-y: auto;
}

.dashboard .pageContainer h4.headline {
    color: #EF6848;
    font-size: 3rem;
    text-transform: capitalize;
    margin: 10px 0;
    font-weight: 600;
}

.dashboard .pageContainer .row {
    background-color: #191919;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    padding: 20px 0 0;
}

.pageContainer .row button {
    border: none;
    cursor: pointer;
    border-radius: 15px;
    padding: 10px 20px;
    font-size: 1rem;
    text-transform: capitalize;
    color: #000;
    font-weight: 300;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(#db4d25, #cb5331);
    box-shadow: 0 -4px 4px #bd401d inset, 0 4px 4px rgba(255, 255, 255, 0.1) inset;
}

.pageContainer .row button .icon {
    fill: #000;
}

.tableContainer::-webkit-scrollbar:horizontal {
    height: 5px;
}

.tableContainer::-webkit-scrollbar-track:horizontal {
    background-color: #0C0C0C;
    border-radius: 5px;
}

.tableContainer::-webkit-scrollbar-thumb:horizontal {
    background-color: #565656;
    border-radius: 5px;
}

.tableContainer {
    display: grid;
    padding: 1.5rem 0;
    row-gap: 20px;
}

.tableContainer .event {
    display: grid;
    width: 100%;
    grid-template-columns: 3fr 1fr auto;
    column-gap: 20px;
    border: 1px solid white;
    border-radius: 20px;
    padding: 20px 40px;
    text-align: left;
}

.tableContainer .event .details h5 {
    font-size: 1.5rem;
}

.tableContainer .event p {
    font-size: 1.3rem;
}

.tableContainer .event div {
    margin: auto 0;
}

.tableContainer .event .controls ul {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 5px;
}

.tableContainer .event .controls ul li button {
    cursor: pointer;
    background-color: rgb(34, 73, 213);
    padding: 10px;
    border-radius: 10px;
    border: none;
    outline: none;
}

.tableContainer .event .controls ul li:nth-child(2) button {
    background-color: rgb(213, 123, 34);
}

.tableContainer .event .controls ul li:nth-child(3) button {
    background-color: rgb(213, 34, 46);
}

.tableContainer .event .controls .controlToggle {
    display: none;
}

@media (max-width: 769px) {
    html {
        font-size: 12px;
    }

    main.mainContainer {
        min-height: 100vh;
        background-color: #fff;
    }

    .dashboard {
        height: auto;
        grid-template-columns: 1fr;
    }

    .dashboard .pageContainer {
        height: 88vh;
        padding: 20px 2rem 20px 3rem;
        width: calc(100vw - 2vh);
        margin-left: 2vh;
    }

    .tableContainer {
        max-height: 65vh;
    }
}

@media (max-width: 426px) {
    html {
        font-size: 12px;
    }

    .tableContainer .event div:nth-child(2) {
        display: none;
    }

    .tableContainer .event .controls {
        position: relative;
        text-align: right;
    }

    .tableContainer .event .controls button {
        background-color: transparent;
        border: none;
        outline: none;
    }

    .tableContainer .event .controls .controlToggle {
        display: inline-block;
    }

    .tableContainer .event .controls button:hover+ul,
    .tableContainer .event .controls ul:hover {
        pointer-events: all;
        opacity: 1;
    }

    .tableContainer .event .controls ul {
        pointer-events: none;
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translate(-50%, -50%);
        transition: 0.3s all ease-in;
    }

    .tableContainer .event .controls ul li button {
        padding: 5px;
    }

    .tableContainer .event {
        column-gap: 10px;
        padding: 10px 20px;
    }

}