.competitionBoardContainer {
  padding: var(--pagePadding);
}
.competitionBoardContainer .boardContainer {
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
}

.competitionBoardContainer .boardContainer > div {
  text-align: center;
}

/* start text style */
.competitionBoardContainer .boardContainer h1 {
  font-size: 60px;
  text-shadow: 0 0 5px white;
}
.competitionBoardContainer .boardContainer h1 span {
  font-weight: 200;
  font-size: 40px;
  text-shadow: none;
}

.competitionBoardContainer .boardContainer h2 {
  font-size: 27px;
  font-weight: 400;
}

.competitionBoardContainer .boardContainer .firstPlace h2 {
  width: 300px;
}

.competitionBoardContainer .boardContainer .secondPlace h2 {
  width: 250px;
}

.competitionBoardContainer .boardContainer .thirdPlace h2 {
  width: 200px;
}

.competitionBoardContainer .boardContainer .members {
  margin-top: 5%;
}

.competitionBoardContainer .boardContainer .members * {
  color: var(--mainRed);
}

/* end text style */

/* start images style */
.competitionBoardContainer .boardContainer .imageContainer {
  position: relative;
  margin: 20% 0 15%;
}
.boardContainer .image {
  background-size: cover;
  border-radius: 50%;
}
.boardContainer .firstPlace .imageContainer img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.boardContainer .secondPlace .imageContainer img {
  position: absolute;
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%);
}

.boardContainer .thirdPlace .imageContainer img {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.boardContainer .firstPlace .image {
  width: 300px;
  height: 300px;
}

.boardContainer .secondPlace .image {
  width: 250px;
  height: 250px;
}

.boardContainer .thirdPlace .image {
  width: 200px;
  height: 200px;
}
/* end images style */

/* start second section style */
.competitionBoardContainer hr {
  border: 1px solid #252525;
  margin: 30px 0;
}

.competitionBoardContainer .teamsContainer {
  display: grid;
  grid-template-columns: 40px 80px auto 100px;
  gap: 10px 20px;
  align-items: center;
}
.competitionBoardContainer .teamsContainer .image {
  width: 70px;
  height: 70px;
  background-size: cover;
  border-radius: 50%;
  outline-offset: 5px;
}

.competitionBoardContainer .teamsContainer .image:first-of-type {
  outline: 3px solid #ffcb00;
}

.competitionBoardContainer .teamsContainer .image:nth-of-type(3) {
  outline: 3px solid #b7bfc5;
}

.competitionBoardContainer .teamsContainer .image:nth-of-type(5) {
  outline: 3px solid #eb6933;
}

.competitionBoardContainer .teamsContainer hr {
  grid-column: span 4;
}

.competitionBoardContainer .teamsContainer .info p:first-child {
  font-weight: 800;
  font-size: 20px;
}

.competitionBoardContainer .teamsContainer .info p:nth-child(2) {
  color: var(--secondFontColor);
}

.competitionBoardContainer .teamsContainer button {
  background-color: transparent;
  border: 2px solid var(--mainRed);
  padding: 10px 0;
  border-radius: 30px;
  cursor: pointer;
}

.competitionBoardContainer .teamsContainer button:hover {
  background-color: var(--mainRed);
}

.competitionBoardContainer .teamsContainer button:active {
  transform: scale(0.96);
}

/* end second section style */

@media (max-width: 1100px) {
  .competitionBoardContainer .boardContainer > div {
    transform: scale(0.85);
  }
}

@media (max-width: 900px) {
  .competitionBoardContainer .boardContainer .imageContainer {
    transform: scale(0.65);
    margin: 5% 0 0;
  }
}

@media (max-width: 700px) {
  .competitionBoardContainer .boardContainer .imageContainer {
    transform: scale(0.55);
  }
  .competitionBoardContainer .boardContainer .firstPlace {
    margin: 0 -20%;
  }
}

@media (max-width: 500px) {
  .competitionBoardContainer .boardContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .competitionBoardContainer .boardContainer .imageContainer {
    transform: scale(1);
    margin: 15% 0 20%;
  }
  .competitionBoardContainer .boardContainer .firstPlace {
    position: relative;
  }
  .competitionBoardContainer .boardContainer .firstPlace::after {
    content: "";
    height: 2px;
    width: 100%;
    background-color: var(--mainRed);
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
  }
  .competitionBoardContainer .boardContainer .firstPlace::before {
    content: "";
    height: 2px;
    width: 100%;
    background-color: var(--mainRed);
    position: absolute;
    bottom: -70px;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media (max-width: 395px) {
  .competitionBoardContainer .boardContainer .imageContainer {
    transform: scale(0.85);
  }
  .competitionBoardContainer .teamsContainer {
    grid-template-columns: 45px 60px auto 85px;
    gap: 5px;
  }
  .competitionBoardContainer .teamsContainer .image {
    width: 50px;
    height: 50px;
  }
  .competitionBoardContainer hr {
    border: 1px solid #252525;
    margin: 10px 0;
  }
  .competitionBoardContainer .teamsContainer button {
    padding: 10px 0;
    border-radius: 30px;
    cursor: pointer;
  }
}
