.oldEventsContainer {
    display: grid;
    width: 100vw;
    margin: 0;
    padding: 0 2rem 2rem;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
}

.singleEvent {
    height: 300px;
    background-size: cover;
    display: flex;
    border: 2px solid #f25123;
    border-radius: 50px;
    overflow: hidden;
    align-items: flex-end;
    box-shadow: 0px -110px 100px 0px #0c0c0c inset;
}

div.eventData {
    background-image: linear-gradient(0deg,#000,60%,transparent);
    padding: 25px;
    width: 100%;
}

div.eventData .lower {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
}

.eventNameContainer {
    display: inline-flex;
    align-items: center;
    gap: 10px;
}

p.eventDate {
    font-weight: 200;
    font-size: 20px;
}

.eventNameContainer h1 {
    font-weight: 300;
    font-size: 35px;
    color: #f25123;
    white-space: normal;
}

.eventNameContainer p {
    border: 2px solid #f25123;
    border-radius: 20px;
    padding: 0px 8px;
    font-size: 12px;
}

.eventBriefDesc {
    white-space: normal;
}

.detailesBtn {
    align-self: flex-end;
    background-color: transparent;
    border: 2px solid #f25123;
    padding: 5px 15px;
    border-radius: 20px;
    min-width: 100px;
    cursor: pointer;
}

.detailesBtn:hover {
    background-color: #f25123;
}

@media (max-width: 769px) {
    .oldEventsContainer {
        grid-template-columns: 1fr;
    }
}

@media (max-width: 485px) {
    .oldEventsContainer {
        display: grid;
        padding: 0 4%;
        grid-template-columns: 1fr;
        gap: 40px;
    }
    .singleEvent {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .detailesBtn {
        align-self: flex-start;
        margin: 10px 20px 20px;
    }
    .eventData {
        padding: 0 30px;
    }
    .eventDate {
        font-size: 16px;
    }
    .eventNameContainer h1 {
        font-weight: 300;
        font-size: 35px;
        color: #f25123;
        white-space: normal;
    }
}